import { v4 as uuidv4 } from 'uuid'
function chillHop() {
  return [
    {
      name: 'Silver Sapphire (feat. oksami) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/2986-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeiah35nnrikwv3xotopmbyr2qbgjb7yx232oxe4cgg7de7urtqfvoa/oksami - Silver Sapphire.wav',
      id: 'eaac0711-37a3-4872-89c1-105b388afecc',
      active: true,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'Never Lived A Day (feat. Kallaway) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/1751-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeiggl6cytiag4dxyuqam4kgkwo4iddzxizo6oxqov5i5rw5dat2twm/Never Lived A Day_Kallaway.mp3',
      id: 'e4ecd874-21e5-443f-bb3b-bfabcc7ac189',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'Hot Cocoa Fingers (feat. DEFFIE) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/735-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeicbi7i333zbvu4rnmnxlwxdv7m5t3j7yxnmiak3xa4ptowj5s73qe/00 - DEFFIE - MICHAEL BROWN.mp3',
      id: '41627aee-07c3-4857-a6b5-232fd3f188d5',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'We Buy Your Big Macs (feat. oshi) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/1787-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeiaiyjtamh4s3q33egbwlsxmglwzcewwhrig3hngjnls7qq6e35hqm/chrome!!!.mp3',
      id: 'c9ac9050-6b61-465d-a0a7-02edd4ebbb29',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'The X Axis (feat. Joel) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/1474-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeigknqba2v757ipewgki7g2k4k5md2t7zw664p4euh3garsfnj7yqq/Intro.mp3',
      id: 'a1e6ec82-33b9-447b-a906-78b9a2be59b0',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'Space Cadillac (feat. j2p2) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/1522-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeiccerrisz7gx72mqmoywwwimrbtz2gmtgdl4jf46j5f5p77s2g7ay/halt-and-catch-fire-theme-song.mp3',
      id: '5f26ae92-4bb0-4dae-bfcd-c435c4def769',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'Couch Potato Confidential (feat. Ricky Sour) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/4403-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeicb3blnode6fqimbki6tlagnbuutvedbxotsvdbyrwzccr72vhdau/the good old days are now - ricky sour- 90.38 C major.mp3',
      id: 'df3ce986-b38e-464e-a9bf-79b5e4945fac',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'Hear No Evil, See No Good (feat. MICKY) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/4449-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeiha3oxwmselmtffaqgd2a6cvuxbhkpjvrsqs22wm2bmbmbh6jkcei/MICKY - Get Some Rest (demo 9:22:21).mp3',
      id: '6f747fa8-8118-4be9-816b-5ee5e7a6e423',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'Hear No Evil, See No Good (feat. ps) (Remix)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/4449-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeihxbppgoxnaxg6zmhyncwmsqajb7zsmvup7zjhwrbexezi5ai4mii/Tune for tunesproject - 9:22:21, 10.36 AM.wav',
      id: '2c0c3e90-054a-4747-9d23-d299fdf18369',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'A Penny State of Mind (feat. 12:01) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/3801-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeia3xqegmsesicjrtcxbrztmnzy5ydtu6zthsyxrlxvqhdmcvc5yem/Penny State Of Mind (2021).mp3',
      id: 'c7b657b7-09e8-4e35-83a6-675b75444647',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'Sunny Day, Soapy Bath (feat. GrizzRivers) (Remix)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/109-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeif5obiqqulbkewv7e5dlw34vi6bur6zi33hhy3cfcwwstwqptkao4/GrizzRivers - Use.wav',
      id: 'eeb95a3a-909d-445a-bd8f-561689a17b71',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: "I'm Immune to Thyme (feat. Sudo) (Original)",
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/2777-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeiff5lgmllohco2fzyw7cw7nyvyxsajqraa3ip46sfadfhzip5txre/1010 - ycboys.mp3',
      id: 'db82eedb-f1d4-428d-88f3-3edf006fd2d4',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'Make It So (feat. ekko) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/2556-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeifebjuu6uanl75r4xudwaysna7udiucas65mr5ay2xjsuyk72ved4/make-it-so2.mp3',
      id: '6577b590-30cd-489c-ada5-2e733efbdeba',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'Cyberpunk Nomads (feat. oksami) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/2992-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeibqxi3gggme5yaxgrkufcqj53jwnynovsd73mlrfkvfz4rtq6s4nu/Cyberpunk Nomads.wav',
      id: '12932fb9-2689-42ad-8607-6093d3760d7a',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: "It's Not a Phase, Mom (feat. GrizzRivers) (Remix)",
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/3374-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeicvdohrp6vr72o5c5raotlftwb4vzrjbsj34opfnniuy55afck35e/GrizzRivers - b4iwasborn.wav',
      id: '77e72ff4-13ad-46fb-94b4-7cf7e41f42c6',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'Crypto In Love (feat. Sudo) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/2717-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeigy5glijql4ppbafiepn3zvbeiut3rd74poejgpwdj3hbkeso4hce/0218 - prophet.mp3',
      id: 'b69d49fe-eae8-4324-a02b-2472d8fb80df',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'At 4am Pt. 2 (feat. Sudo) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/2714-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeihgsforo4utginjgvbs23jqwlcw7fbbxzfegqol5ehzdz6xtqke34/0426 - stressed.mp3',
      id: '8635b68d-d586-4964-b514-558f4c4fd95b',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'La Croix When Its Cold (feat. Sudo) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/3806-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeigkymdfceaaznx2z7whovjtlqv5tz3psrzws47hromrna3v2z3y7a/0907 - lofifunkbeat.wav',
      id: '1285bc2b-b05f-4bc6-910c-8ef8a0674d59',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'Worst Case Scenario (feat. Sudo) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/2818-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeiak7nhpcqcfi7i2mxvzm42vml5j6zhly5htcyb44lfpgqjr5eqt7a/0403 - trash.mp3',
      id: '45c388c6-fc4f-4fbb-96ab-c3af3238c779',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'I Wanna Dance on a Volcano (feat. Sudo) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/2840-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeif6sdh7nqzqe34tlvluykwsmp7cdikyingnjro4nqn3dcsyvkd6ku/0421 - illeniuminspo v2.mp3',
      id: 'f49522e8-483e-4262-9df3-0b7245afee1f',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'Once You Take Off Your Wig (feat. oshi) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/4737-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeibvoi6it6kcek2yi5xw5ugkpylndocegpm7rqwltnyzg47qx3fl3a/i aint no bunny rabbit.mp3',
      id: '549bd9dc-6bec-43cf-b654-e4ed4c90a0e9',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'My Ass Belongs to Microsoft (feat. DEFFIE) (Original)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/2696-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeihxfbv4maccw7qq3x6xtgl5dnuy43dsgqr4rg5golgo5eipzir52i/420BPM.mp3',
      id: 'abf82d67-5067-4e3a-ad61-db6a19d1db56',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: "Dinesh Chugtai's Song feat. merwane (Remix)",
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/4187-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeibfvijctyvsb7ketbfqppxoxy77t3yyddzwzyzi2s3di7f7iq4gwy/treetone.mp3',
      id: '86e294ca-adae-4237-81ac-f751c62a6510',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'merwane (Remix)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/4226-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeia7qxtythv2pbexexmeqpircexlvjvxlojvy6ngzi24v5rtprvmri/ahhhh.mp3',
      id: '16f9bc40-a050-40fd-bdb7-05ccd6cac172',
      active: false,
      color: ['#205950', '#2ab3bf']
    },
    {
      name: 'merwane (Remix)',
      cover:
        'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/4221-composite.png',
      audio:
        'https://ipfs.io/ipfs/bafybeidps4cvns4pb5d3pwbwdtwd4dlq526hxzm5wgsewtkbiyhfvyl37q/mix.mp3',
      id: '4bc28493-3947-4c5e-83ee-c0798eaae1e7',
      active: false,
      color: ['#205950', '#2ab3bf']
    }
    // {
    //   name: 'Beaver Creek',
    //   cover:
    //     'https://ipfs.io/ipfs/Qmcu552EPV98N9vi96sGN72XJCeBF4n7jC5XtA1h3HF5kC/1522-composite.png',
    //   artist: 'Aso, Middle School, Aviino',
    //   audio:
    //     'https://ipfs.io/ipfs/bafybeiccerrisz7gx72mqmoywwwimrbtz2gmtgdl4jf46j5f5p77s2g7ay/halt-and-catch-fire-theme-song.mp3',
    //   color: ['#205950', '#2ab3bf'],
    //   id: uuidv4(),
    //   active: true
    // }
    // {
    // 	name: "Daylight",
    // 	cover:
    // 		"https://chillhop.com/wp-content/uploads/2020/07/ef95e219a44869318b7806e9f0f794a1f9c451e4-1024x1024.jpg",
    // 	artist: "Aiguille",
    // 	audio: "https://mp3.chillhop.com/serve.php/?mp3=9272",
    // 	color: ["#EF8EA9", "#ab417f"],
    // 	id: uuidv4(),
    // 	active: false,
    // },
    // {
    // 	name: "Keep Going",
    // 	cover:
    // 		"https://chillhop.com/wp-content/uploads/2020/07/ff35dede32321a8aa0953809812941bcf8a6bd35-1024x1024.jpg",
    // 	artist: "Swørn",
    // 	audio: "https://mp3.chillhop.com/serve.php/?mp3=9222",
    // 	color: ["#CD607D", "#c94043"],
    // 	id: uuidv4(),
    // 	active: false,
    // },
    // {
    // 	name: "Nightfall",
    // 	cover:
    // 		"https://chillhop.com/wp-content/uploads/2020/07/ef95e219a44869318b7806e9f0f794a1f9c451e4-1024x1024.jpg",
    // 	artist: "Aiguille",
    // 	audio: "https://mp3.chillhop.com/serve.php/?mp3=9148",
    // 	color: ["#EF8EA9", "#ab417f"],
    // 	id: uuidv4(),
    // 	active: false,
    // },
    // {
    // 	name: "Reflection",
    // 	cover:
    // 		"https://chillhop.com/wp-content/uploads/2020/07/ff35dede32321a8aa0953809812941bcf8a6bd35-1024x1024.jpg",
    // 	artist: "Swørn",
    // 	audio: "https://mp3.chillhop.com/serve.php/?mp3=9228",
    // 	color: ["#CD607D", "#c94043"],
    // 	id: uuidv4(),
    // 	active: false,
    // },
    // {
    // 	name: "Under the City Stars",
    // 	cover:
    // 		"https://chillhop.com/wp-content/uploads/2020/09/0255e8b8c74c90d4a27c594b3452b2daafae608d-1024x1024.jpg",
    // 	artist: "Aso, Middle School, Aviino",
    // 	audio: "https://mp3.chillhop.com/serve.php/?mp3=10074",
    // 	color: ["#205950", "#2ab3bf"],
    // 	id: uuidv4(),
    // 	active: false,
    // },
    //ADD MORE HERE
  ]
}

export default chillHop
